.active {
  background-color: rgba(39, 166, 244, 1);
  width: 100%;
}

#navlink {
  padding: 10px 5px;
  margin: 5px 10px;
  text-decoration: none;
  border-radius: 15px;
  width: 100%;
  text-transform: none;
}
#navlink:hover {
  background-color: rgba(39, 166, 244, 1);
}

#navlink:focus {
  background-color: rgba(39, 166, 244, 1);
}
